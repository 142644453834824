import { PersonEntry } from "frontend/contentful/schema/semantics";

export function getPersonName(person: PersonEntry | undefined): string {
  if (!person) return "";
  if (!person.fields) return "";

  if (person.fields.displayName) {
    return person.fields.displayName;
  }

  return [
    person.fields.firstName,
    person.fields.middleName,
    person.fields.lastName,
    person.fields.nameSuffix,
  ]
    .filter((name) => Boolean(name))
    .join(" ");
}
